import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import { setupListeners } from '@reduxjs/toolkit/query';
import storage from 'redux-persist/lib/storage';
//slices
import authReducer from "../slice/authSlice"
import tanProfileReducer from '../slice/tanProfileSlice';
import historyReducer from "../slice/historySlice"
import displayReducer from '../slice/displaySlice'
import filterReducer from '../slice/filterSlice';
import paymentReducer from '../slice/paymentSlice'
import sessionReducer from '../slice/sessionSlice'
//rtk query api
import { api } from '../services/authApi';
import { paymentApi } from '../services/paymentApi';
import { tanProfilesApi } from '../services/tanProfilesApi';
import { tanHistoryApi } from '../services/historyApi';
import { filterApi } from '../services/filterApi';
import { sessionApi } from '../services/sessionApi';
import session from 'redux-persist/lib/storage/session';



// 1. Set up persist configuration
const persistConfig = {
  key: 'root',
  storage,  // By default, it uses localStorage
  whitelist: ['display', "auth", "tanProfile", "session"], // Persist these slices only
};

// 2. Combine your reducers
const rootReducer = combineReducers({
  auth: authReducer,
  session: sessionReducer,
  display: displayReducer,
  tanProfile: tanProfileReducer,
  history: historyReducer,
  filter: filterReducer,
  payment: paymentReducer,
  [api.reducerPath]: api.reducer, // Do not persist RTK Query slices
  [paymentApi.reducerPath]: paymentApi.reducer, // Do not persist RTK Query slices
  [tanProfilesApi.reducerPath]: tanProfilesApi.reducer,
  [tanHistoryApi.reducerPath]: tanHistoryApi.reducer,
  [filterApi.reducerPath]: filterApi.reducer,
  [sessionApi.reducerPath]: sessionApi.reducer,
});

// 3. Create persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// 4. Configure the store with middleware from RTK Query and persisted reducer
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,  // Required for non-serializable actions like Promises in RTK Query
    })
    .concat(api.middleware)
    .concat(sessionApi.middleware)
    .concat(paymentApi.middleware)
    .concat(tanProfilesApi.middleware)
    .concat(tanHistoryApi.middleware)
    .concat(filterApi.middleware),
});

// 5. Export the persistor to be used in PersistGate
export const persistor = persistStore(store);
