import React, { useState, useEffect} from 'react';
import { useGetPaymentMethodsQuery } from '../../redux/services/paymentApi';
import SavedCardDisplay from '../../components/stripeCard/SavedCardDisplay';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Spinner, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { setEditing, setPaymentMethods } from '../../redux/slice/paymentSlice';
import CenteredToast from '../../components/centeredToast/centeredToast';
import { useDeletePaymentMethodMutation } from '../../redux/services/paymentApi';

const PaymentMethod = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'paymentMethod' });
  const [showAlert, setShowAlert] = useState(null)
  const navigate = useNavigate();
  const { data, isLoading, error, refetch } = useGetPaymentMethodsQuery();
  const paymentMethods = useSelector((state) => state?.payment?.paymentMethods);
  const [deletePaymentMethod, {
    isLoading: isLoadingDelete,
    isSuccess: isDeleteSuccess,
    error: deleteError
  }] = useDeletePaymentMethodMutation();
  const dispatch = useDispatch()
  useEffect(()=>{
    if (isDeleteSuccess) {

      refetch(); // This will rerun the query to fetch updated payment methods
    }
  },[isDeleteSuccess])
  
  return (
    <Container className="mt-4">
      <h1 className="text-center mb-4">{t("title", "Payment Methods")}</h1>
      {isLoading || isLoadingDelete || !paymentMethods ? (
        <div className="text-center my-4">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">{t("loading", "Loading...")}</span>
          </Spinner>
        </div>
      ) : error ? (
        <Alert variant="danger" className="text-center">
          {t("error", "Error loading payment methods:")} {error.message}
        </Alert>
      ) : (
        <Row className="justify-content-center">
          {paymentMethods && paymentMethods.length > 0 ? (
            paymentMethods.map((method, index) => (
              <Col xs={12} sm={8} md={6} lg={4} key={index} className="mb-4" onClick={() => {
                // dispatch(setEditing(method))
                // navigate("addPaymentMethod")
              }}>
                <SavedCardDisplay card={method} remove={() => {
                  setShowAlert(method)
                }} />
              </Col>
            ))
          ) : (
            <Col xs={12} className="text-center">
              <p>{t("noCards", "No saved cards available.")}</p>
            </Col>
          )}
          {/* Add New Card Button */}
          <Col xs={12} sm={8} md={6} lg={4} className="d-flex align-items-center justify-content-center mt-3">
            <Button variant="primary" onClick={() => navigate('/addPaymentMethod')}>
              {t("addCardButton", "+ Add New Card")}
            </Button>
          </Col>
        </Row>
      )}
      {showAlert && <CenteredToast
        show={showAlert}
        onHide={(() => { setShowAlert(false) })}
        onAccept={() => {
          if (showAlert?.id) {
            deletePaymentMethod(showAlert.id)
            dispatch(setPaymentMethods(null))
          }
        }}
        onCancel={() => { console.log("Cancel") }}
        title={t("alert.title", "Delete Card")}
        body={t("alert.body", "Would you like to delete this card?")}
        acceptButtonText={t("alert.button", "Delete")}
        closeOnOverlayClick={true}
      ></CenteredToast>}
    </Container>
  );
};

export default PaymentMethod;
