import React, { useEffect, useState } from 'react';
import './tanSelection.css'; // Add custom CSS for styling
import { Container } from 'react-bootstrap';
import SquareButton from '../../components/squareButton/squareButton';
import NavigationWrapper from '../../components/sessionNavigation/navigationWrapper';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setProfileMeta, setSelected, getSessionComponentsPretty, removeComponentsByName, setTypes } from '../../redux/slice/tanProfileSlice';
import { useLazyGetComponentsQuery } from '../../redux/services/tanProfilesApi';
import CenteredToast from '../../components/centeredToast/centeredToast';
import "./sessionSelection.css"
import { getSessionOptions } from '../../redux/slice/tanProfileSlice';
import {slice_map } from '../../components/componentMap/componentMap';
const SessionSelection = () => {
  const { t } = useTranslation("translation", { keyPrefix: "sessionSelection" });
  const items = useSelector(getSessionComponentsPretty)
  const sessionOptions = useSelector(getSessionOptions)
  const letsGo = useNavigate()
  const tanProfile = useSelector((state) => state?.tanProfile)
  const mappedComponents = useSelector((state) => state?.tanProfile?.mappedComponents)
  const display = useSelector((state) => state?.display)
  const dispatch = useDispatch()
  const [showAlert, setShowAlert] = useState(false)
  const fromBooth = tanProfile?.profileMeta?.profileType === "booth" || tanProfile?.profileMeta?.profileType === "scanBooth"
  const [getComponents, { isLoading, isSuccess, isError }] = useLazyGetComponentsQuery(  );
  
  useEffect(() => {
    getComponents()
    if (
      !fromBooth &&
      tanProfile?.selectedProfile?.session_component.length > 0 &&
      !display?.sidebar?.open && 
      tanProfile?.profileMeta?.profileType !== "edit"
    ) {
      setShowAlert(true)
    }
  }, [])
  useEffect(() => {
    if (!tanProfile?.profileMeta?.options.length && mappedComponents) {
      dispatch(setProfileMeta({ profileType: "new", options: slice_map(mappedComponents) }))
    }
    if (tanProfile?.profileMeta?.navigationDirection) {
      let profileMeta = {...tanProfile?.profileMeta}
      profileMeta.navigationDirection = null
      dispatch(setProfileMeta(profileMeta))
    }
  },[mappedComponents])
  const handleReset = () => {
    dispatch(setProfileMeta({ profileType: "new", options: slice_map(mappedComponents) }))
    dispatch(setSelected({
      // session_images: [{}],
      session_name: "",
      session_component: [],
    }))
  }
  const removeOption = (remove) => {
    dispatch(removeComponentsByName(remove))
  }
  return (
    <NavigationWrapper>
      <div className='button-holder'>
        {sessionOptions["Tan"] && <SquareButton className="" onClick={() => {
          removeOption('WellFit')
          dispatch(setTypes(["tan"]))
          letsGo("/session/photoSelection")
        }}>
          {t("selection.tan", "Tan")}
        </SquareButton>}
        {sessionOptions["WellFit"] && <SquareButton className="" onClick={() => {
          removeOption("Base Tan Level")
          dispatch(setTypes(["wellfit"]))
          letsGo("/session/WellFit")
        }}>
          {t("selection.wellfit", "Wellfit")}
        </SquareButton>}
        <SquareButton className="" onClick={() => {
          dispatch(setProfileMeta({ ...tanProfile?.profileMeta, options: slice_map() }))
          dispatch(setTypes(["tan", "wellfit"]))
          letsGo("/session/photoSelection")
        }}>
          {t("selection.tanWellfit", "Tan and Wellfit")}
        </SquareButton>
      </div>
      {showAlert && <CenteredToast
        show={showAlert}
        onHide={(() => { setShowAlert(false) })}
        onAccept={() => { handleReset() }}
        onCancel={() => { console.log("Cancel") }}
        title={t("alert.editTitle", "Existing profile selections")}
        body={t("alert.editText", "It looks like you exited a previous tan profile setup without saving. Would you like to start over?")}
        acceptButtonText={t("alert.button", "Start over")}
        cancelButtonText={t("alert.button", "Resume")}
        closeOnOverlayClick={true}
      ></CenteredToast>}
    </NavigationWrapper>
  );
};

export default SessionSelection;
