import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, Alert, Spinner, Container, Row, Col } from 'react-bootstrap';

function StripeCard ({ onSuccess, existingCard }) {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    if (!stripe || !elements) {
      return; // Stripe.js not yet loaded
    }

    if (existingCard) {
      // Call backend to edit the card based on existingCard details
      // (e.g., update card info via Stripe API if applicable)
    } else {
      // Add new card
      const cardElement = elements.getElement(CardElement);
      const { error, token } = await stripe.createToken(cardElement);

      if (error) {
        setError(error.message);
        setLoading(false)
      } else {
        // Send `paymentMethod.id` to your server to save
        onSuccess(token);
      }
    }

  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement options={{ hidePostalCode: true }} />
      {error && <div style={{ color: 'red' }}>{error}</div>}
      <Button type="submit" disabled={!stripe || loading}>
        {existingCard ? 'Update Card' : 'Add Card'}
      </Button>
    </form>
  );
}

export default StripeCard;
