import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function SavedCardDisplay({ card, remove }) {
  const { t } = useTranslation("translation", { keyPrefix: "savedCardDisplay" });
  if (!card) return null;

  return (
    <Card style={{ width: '18rem', marginBottom: '1rem', position: 'relative' }}>
      <Card.Body>
        <Card.Text>
          <strong>{t("card.brand", 'Card Brand: ')}</strong> {card.brand}
        </Card.Text>
        <Card.Text>
          <strong>{t("card.number", 'Card: ')}</strong>{t("card.stars", ' **** **** **** ')}{card.last4}
        </Card.Text>
        <Card.Text>
          <strong>{t("card.expires", 'Expires: ')}</strong> {card.exp_month}/{card.exp_year}
        </Card.Text>

        {/* Button positioned in the top right corner */}
        {remove && <Button onClick={remove}
          variant="danger"
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            zIndex: 1 // Ensure the button is above other elements
          }}
        >
          <i className="bi bi-trash-fill" style={{ color: "white" }}></i>
        </Button>}

      </Card.Body>
    </Card>
  );
}

export default SavedCardDisplay;
