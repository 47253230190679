import { createSlice } from '@reduxjs/toolkit';
import { paymentApi } from '../services/paymentApi';

const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    paymentMethods: null,
    editing: null,
  },
  reducers: {
    // Action to set the editing value
    setEditing: (state, action) => {
      state.editing = action.payload;
    },
    setPaymentMethods: (state, action) => {
      state.paymentMethods = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(paymentApi.endpoints.getPaymentMethods.matchFulfilled, (state, { payload }) => {
        console.log(payload);
        state.paymentMethods = payload?.payload;
      })
      .addMatcher(paymentApi.endpoints.getPaymentMethods.matchRejected, (state, { error }) => {
        state.error = error;
        state.paymentMethods = null;
      })
      .addMatcher(paymentApi.endpoints.addPaymentMethod.matchFulfilled, (state, { payload }) => {
        console.log(payload);
        // Optionally add the new payment method to the existing list
        // state.paymentMethods = [...(state.paymentMethods || []), payload?.payload];
      })
      .addMatcher(paymentApi.endpoints.addPaymentMethod.matchRejected, (state, { payload }) => {
        console.log(payload);
      });
  },
});

export const { setEditing, setPaymentMethods } = paymentSlice.actions;
export default paymentSlice.reducer;
