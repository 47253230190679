const slice_map = (component_id, arrayOptions) => {
    let arr = []
    for(let i in component_id){
        arr.push({
            sku: i,
            is_empty: 0,
            is_valid: 1,
    })
    }
    return arr
}
export {slice_map};