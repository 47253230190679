import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_BASE_API_URL;

export const sessionApi = createApi({
  reducerPath: 'sessionApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      // Get the user from the auth slice in the Redux store
      const user = getState().auth?.user;

      // If the user is logged in, add the token to the headers
      if (user?.token) {
        headers.set('authorization', `${user.token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    // Login endpoint
    newSession: builder.mutation({
      query: (body) => ({
        url: 'session/new',
        method: 'POST',
        body: body,
      }),
    }),
  }),
});

export const {
  useNewSessionMutation
} = sessionApi;
