// src/redux/services/paymentApi.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_BASE_API_URL;

export const paymentApi = createApi({
  reducerPath: 'paymentApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const user = getState().auth?.user;
      if (user?.token) {
        headers.set('authorization', `${user.token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    // Fetch all payment methods
    getPaymentMethods: builder.query({
      query: () => ({
        url: 'payment_methods',
        method: 'GET',
      }),
    }),

    // Edit an existing payment method
    editPaymentMethod: builder.mutation({
      query: ( body ) => ({
        url: `payment_methods`,
        method: 'PUT',
        body: body,
      }),
    }),

    // Add a new payment method
    addPaymentMethod: builder.mutation({
      query: ( body ) => ({
        url: 'payment_methods',
        method: 'POST',
        body: body,
      }),
    }),

    // Delete a payment method by ID
    deletePaymentMethod: builder.mutation({
      query: (id) => ({
        url: `payment_methods/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetPaymentMethodsQuery,
  useEditPaymentMethodMutation,
  useAddPaymentMethodMutation,
  useDeletePaymentMethodMutation,
} = paymentApi;
