// src/pages/AddPaymentMethod.js
// src/pages/AddPaymentMethod.js
import React, { useState } from 'react';
import { useAddPaymentMethodMutation } from '../../redux/services/paymentApi';
import StripeCard from '../../components/stripeCard/stripeCard';
import { Button, Alert, Spinner, Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
const AddPaymentMethod = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'addPaymentMethod' });
  // const [cardDetails, setCardDetails] = useState(null); // Card details from StripeCard
  const [submitError, setSubmitError] = useState(null);
  const [addPaymentMethod, { isLoading, error }] = useAddPaymentMethodMutation();
  const navigate = useNavigate();

  const handleAddCard = async (cardDetails) => {
    setSubmitError(null);
    if (!cardDetails) return;

    try {
      await addPaymentMethod({ card_id: cardDetails.id }).then((data)=>{
        if(data?.data?.payload?.id){
          navigate("/paymentMethods")
        }
      });
      // alert(t('notification.success', 'Card added successfully!'));
      // setCardDetails(null); // Reset form
      
    } catch (err) {
      setSubmitError(t('error.submitFailed', 'Failed to add card. Please try again.'));
    }
  };

  return (
    <Container className="mt-4">
      <Row className="justify-content-center">
        <Col md={6}>
          <h2>{t('header.title', 'Add Payment')}</h2>
          <StripeCard onSuccess={(details) => handleAddCard(details)} />
          {error && <Alert variant="danger">{t('error.apiError', 'Error: {{message}}', { message: error.msg })}</Alert>}
          {submitError && <Alert variant="danger">{submitError}</Alert>}
        </Col>
      </Row>
    </Container>
  );
};

export default AddPaymentMethod;
