import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setQrCode } from '../../redux/slice/sessionSlice';
import SquareButton from '../../components/squareButton/squareButton';
import { useTranslation } from 'react-i18next';
import { setScannedBoothComponents, setScannedBoothData, setProfileMeta } from '../../redux/slice/tanProfileSlice';
import { useLazyGetBoothPricingQuery, useLazyGetScannedBoothQuery, useLazyGetComponentsQuery } from '../../redux/services/tanProfilesApi';
import { useNewSessionMutation } from '../../redux/services/sessionApi';
import { slice_map } from '../../components/componentMap/componentMap';
const ScanLanding = () => {
  const { t } = useTranslation("translation", { keyPrefix: "scanLanding" });

  const [getComponents, { isLoading: componentsLoading, isSuccess: compSuccess, isError: compError }] = useLazyGetComponentsQuery(  );
  const [getBoothPricing, { isLoading: pricingLoading, isSuccess: pricingSuccess, isError: pricingError }] = useLazyGetBoothPricingQuery(  );
  const [getScannedBooth, { isLoading: scannedBoothLoading, isSuccess: scannedBoothSuccess, isError: scannedBoothError }] = useLazyGetScannedBoothQuery(  );
  const [setNewSession, { isLoading: loadingNewSession, isSuccess: newSessionSuccess, isError: newSessionError }] = useNewSessionMutation(  );
  const prevOptions = useRef()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let uid = queryParams.get('uid') || "";
  const numbers = queryParams.get('numbers') || "";
  const user = useSelector((state) => state?.auth?.user);
  const session = useSelector((state) => state?.session);
  const tanProfile = useSelector((state) => state?.tanProfile);
  const timeout = 10 * 60 * 1000; // where should we put this..
  let timeStamp = parseInt(numbers.split('').reverse().join('')) * 1000;
  // const numbers = new Date().getTime().toString().substr(0,10).split('').reverse().join('') // Logic will need changed before Sat Nov 20 2286 12:46:39 GMT-0500 (Eastern Standard Time)
  console.log(tanProfile)
  console.log(session)
  setProfileMeta({ profileType: null, options: [] })

  if (session?.qrCode?.timeStamp) {
    if ((new Date().getTime() - timeout > session?.qrCode?.timeStamp && user) || queryParams.size > 0 && session?.qrCode?.uid !== uid) {
      let qrCode = {
        uid: null,
        timeStamp: null,
      }
      if (queryParams.size > 0 && session?.qrCode?.uid !== uid) {
        qrCode.uid = uid
        qrCode.timeStamp = timeStamp
      }
      dispatch(
        setQrCode(qrCode)
      );
    } else {
      uid = session?.qrCode?.uid
      timeStamp = session?.qrCode?.timeStamp
    }
  }

  const timeNotElapsed = new Date().getTime() - timeout < timeStamp;
  if (uid && timeStamp && !session?.qrCode?.timeStamp && timeNotElapsed) {
    dispatch(
      setQrCode({
        uid: uid,
        timeStamp: timeStamp,
      })
    );
  }
  console.log(queryParams.size)


  useEffect(() => {
    if (queryParams.size > 0) {
      navigate('/scanLanding')
    }
  }, [session?.qrCode?.timeStamp]);

  useEffect(() =>{
    if(!scannedBoothLoading){
      getScannedBooth(uid)
    }
    if(!pricingLoading){
      getBoothPricing(uid)
    }
    if(!componentsLoading){
      getComponents()
    }
  },[])
  useEffect(() => {
    if(tanProfile?.profileMeta?.navigationDirection === "sessionSelection" && queryParams.size === 0 && session?.session?.sid){ //wait for setting of options prior to navigation
      navigate('/session/sessionSelection')
    }
  },[session?.session?.sid])

  return (
    // {(compSuccess && pricingSuccess && scannedBoothSuccess) ?(
    //    ): null
    // }
    (compSuccess && pricingSuccess && scannedBoothSuccess) ? <div className="text-center mt-4">
      {user ? timeNotElapsed ? (
        <>
          <p>{t("text.ready", "Booth ready! Would you like to start a session?")}</p>
          <SquareButton onClick={() => {
            setNewSession({
              boothId: tanProfile?.scannedBoothData?.id
            })
            dispatch(setProfileMeta({ profileType: "scanBooth", navigationDirection: 'sessionSelection', options: slice_map(tanProfile?.mappedComponents, tanProfile?.setScannedBoothData?.last_booth_state?.inventory) }))            
            
            }}>
            {t("button.startSession", "Start Session")}
          </SquareButton>
          <SquareButton onClick={() => {
            dispatch(
              setQrCode({
                uid: null,
                timeStamp: null,
              })
            )
            navigate('/greeting')
          }}>
            {t("button.cancel", "Cancel")}
          </SquareButton>
        </>
      ) : (<div className="text-center mt-4">
        <p>{t("text.expired", "QR Code is expired! Please scan it again to get started!")}</p>
        <SquareButton onClick={() => navigate('/qrCodeScan')}>{t("button.scanNew", "Scan New QR Code")}</SquareButton>
      </div>) : (
        <>
          <p>{t("text.noUser", "It looks like you aren't signed in. Please Login or make an account to get started.")}</p>
          <SquareButton onClick={() => navigate('/login')}>{t("button.login", "Login")}</SquareButton>
          <SquareButton onClick={() => navigate('/createAccount')}>{t("button.createAccount", "Create Account")}</SquareButton>
        </>
      )}
    </div> : <div> loading</div>
  );
};

export default ScanLanding;
