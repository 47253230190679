import { createSlice } from '@reduxjs/toolkit';
import { sessionApi } from '../services/sessionApi';

const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    session: null,
    qrCode: {
        uid: null,
        timeStamp: null
    }
  },
  reducers: {
    clearSession: (state) => {
      state.session = null;
    },
    setQrCode: (state, action) => {
        state.qrCode = action.payload;
      },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(sessionApi.endpoints.newSession.matchFulfilled, (state, { payload }) => {
        console.log(payload)
        state.session = payload?.payload
      })
      .addMatcher(sessionApi.endpoints.newSession.matchRejected, (state, { error }) => {
         console.log(error.message);
      })
  },
});

export const { clearSession, setQrCode } = sessionSlice.actions;
export default sessionSlice.reducer;
